@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.filter-item {
	padding: 6px 12px;
	font-size: 12px;
	line-height: 16px;
	border: 1px solid $color-border-light;
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;

	&.active,
	&:hover {
		background-color: $color-blue-ghost;
		border: 1px solid transparent;
	}

	&.active {
		color: $color-blue-focus;
		font-weight: 500;

		.filter__icon.icon {
			-webkit-filter: invert(50%) sepia(20%) saturate(4640%) hue-rotate(204deg) brightness(96%)
				contrast(86%);
			filter: invert(50%) sepia(20%) saturate(4640%) hue-rotate(204deg) brightness(96%)
				contrast(86%);
		}
	}

	.filter__icon {
		display: none;

		&.icon {
			display: block;
			width: 16px;
			height: 16px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			margin-right: 5px;
		}
	}
	@include max-width(768px) {
		background-color: $color-app-main;
		&.active {
			color: $color-blue-focus;
			background-color: #5d83e433;
			border: 1px solid #5d83e426;
		}
	}
}
