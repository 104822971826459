.main {
	&__buttons-block {
		grid-column: 1/3;
		grid-row: 2;
		display: flex;
		justify-content: space-between;

		&-item {
			min-width: 260px;
		}

		.active {
			pointer-events: none;
		}
	}

	@media (max-width: 1375px) {
		&__buttons-block {
			&-item {
				min-width: 210px;
				height: fit-content;
			}
		}
	}

	@media (max-width: 992px) {
		&__buttons-block {
			align-items: flex-end;
		}
	}

	@media (max-width: 768px) {
		&__buttons-block {
			flex-wrap: wrap;
			justify-content: center;
			column-gap: 10px;
			row-gap: 20px;
			padding-top: 20px;
		}
	}
}
