@import '../../assets/styles/variables';

.global-nots {
	position: fixed;
	bottom: -100px;
	left: 50%;
	z-index: 1000;
	transform: translateX(-50%);
	transition: $transition-main;
	opacity: 0;

	&.visible {
		bottom: 12px;
		opacity: 1;
	}

	.global-not + .global-not {
		margin-top: 12px;
	}
}
