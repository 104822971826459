@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.avatar-file-reader,
.avatar-file-reader__label {
	position: absolute;
	width: 96px;
	height: 96px;
	border-radius: 50%;
	top: 0;
	left: 0;
}

.avatar-file-reader.small,
.avatar-file-reader.small > .avatar-file-reader__label {
	width: 26px;
	height: 26px;
}

.avatar-file-reader {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $color-grey-light;
	box-shadow: 0px 2px 8px rgba(56, 70, 83, 0.2);

	& > i {
		display: block;
		width: 44px;
		height: 44px;

		@include bg-image('svg/avatar-photo.svg', center);
	}

	& > input {
		display: none;
	}

	&__label {
		cursor: pointer;
	}

	&.small {
		top: auto;
		left: auto;
		right: 0;
		bottom: 0;

		& > i {
			width: 14px;
			height: 14px;
		}
	}

	@include max-width(768px) {
		&.image {
			background: transparent;

			i {
				display: none;
			}
		}
	}
}
