@import '../../assets/styles/mixins';
@import '../../assets/styles/colors';

.bread-crumbs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 18px;
	font-size: 16px;
	line-height: 24px;
	opacity: 0.7;

	&__link {
		color: $color-text-black;
		&:hover {
			text-decoration: underline;
			color: $color-text-black;
		}
	}

	&__separator {
		margin: 0 10px;
	}

	@include max-width(768px) {
		display: none;
	}
}
