@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.profile-block {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
	padding-left: 16px;

	&__profile {
		display: flex;
		align-items: center;

		.avatar__image {
			z-index: 1;
			&-blur {
				display: block;
				position: absolute;
				z-index: 0;
				width: 100%;
				height: 100%;
				top: 0.3rem;
				left: 50%;
				transform: translateX(-50%);
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				-moz-filter: blur(8px);
				-o-filter: blur(8px);
				-ms-filter: blur(8px);
				filter: blur(8px);
				opacity: 0.5;
				border-radius: 50%;
			}
		}
	}

	&__info {
		margin-left: 16px;
		font-weight: 500;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: 0.04em;
		color: $color-text-black;

		& > .name,
		& > .points,
		& > .points > .points__count {
			font-weight: inherit;
			letter-spacing: inherit;
		}
	}

	&.main {
		margin-bottom: 30px;
		padding-left: 20px;
		padding-right: 20px;
		width: calc(100% - 364px);

		.profile-block__info {
			margin-left: 28px;
		}
	}

	@include max-width(768px) {
		&.main {
			width: 100%;
			position: relative;

			& > .profile-block__profile {
				flex-wrap: wrap;
				width: 100%;

				& > .avatar {
					display: flex;
					justify-content: center;
					width: 100%;
					margin-bottom: 10px;

					& > .avatar__image {
						width: 120px;
						height: 120px;

						& > .avatar-file-reader {
							width: 120px;
							height: 120px;

							& > .avatar-file-reader__label {
								width: 120px;
								height: 120px;
							}
						}
					}
				}

				& > .profile-block__info {
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					width: 100%;
					margin-left: 0;

					& > .name {
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}
}
