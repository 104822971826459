@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.certificates-modal-content {
	&__print {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-blue-focus;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0.04em;
		cursor: pointer;

		i {
			display: block;
			margin-right: 10px;
			width: 24px;
			height: 24px;

			@include bg-image('svg/print.svg', center);
		}
	}

	&__print-window {
		padding: 20px;
	}
}
