@import '../../../../../assets/styles/colors';

.event-programm-tabs {
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 22px;

	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 100%;
		height: 1px;
		background-color: $color-border-light;
		transform: translateX(-50%);
		content: '';
	}
}
