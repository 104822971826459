@import '../../../../../../../assets/styles/_colors.scss';
@import '../../../../../../../assets/styles/_mixins.scss';

.profit-second__icon-item-wrapper {
	display: inline-block;
	position: absolute;

	.profit-second__icon-item {
		display: flex;
		align-items: center;

		&.rigth {
			.label {
				order: 2;
			}
		}

		.label {
			color: $color-landing-white;
			padding: 0 20px;
		}

		.icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 64px;
			min-height: 64px;
			border-radius: 20px;
			background-color: $color-landing-white;

			/*сделать нормальные иконки*/
			.icon {
				display: block;
				min-width: 36px;
				min-height: 36px;

				&-forums {
					@include bg-image(
						'/landing/icons/circle-icons/forums.svg',
						center,
						contain
					);
				}

				&-conferences {
					@include bg-image(
						'/landing/icons/circle-icons/conferences.svg',
						center,
						contain
					);
				}

				&-festival {
					@include bg-image(
						'/landing/icons/circle-icons/festival.svg',
						center,
						contain
					);
				}

				&-meetup {
					@include bg-image(
						'/landing/icons/circle-icons/meetup.svg',
						center,
						contain
					);
				}

				&-workshops {
					@include bg-image(
						'/landing/icons/circle-icons/workshops.svg',
						center,
						contain
					);
				}

				&-hackaton {
					@include bg-image(
						'/landing/icons/circle-icons/hackaton.svg',
						center,
						contain
					);
				}
			}
		}
	}

	&.forums {
		left: -24%;
		top: 16%;
	}

	&.conferences {
		left: -43%;
		top: 45%;
	}

	&.festival {
		left: -30%;
		bottom: 16%;
	}

	&.meetup {
		right: -24%;
		top: 16%;
	}

	&.workshops {
		right: -36%;
		top: 45%;
	}

	&.hackaton {
		right: -27%;
		bottom: 16%;
	}

	@media (max-width: 1200px) {
		.profit-second__icon-item {
			.icon-wrapper {
				min-width: 55px;
				min-height: 55px;
			}
		}

		&.forums {
			left: -26%;
		}

		&.conferences {
			left: -49%;
		}

		&.festival {
			left: -33%;
		}

		&.meetup {
			right: -26%;
		}

		&.workshops {
			right: -41%;
		}

		&.hackaton {
			right: -30%;
		}
	}

	@media (max-width: 768px) {
		&.forums {
			left: -23%;
		}

		&.conferences {
			left: -43%;
		}

		&.festival {
			left: -29%;
		}

		&.meetup {
			right: -23%;
		}

		&.workshops {
			right: -36%;
		}

		&.hackaton {
			right: -26%;
		}
	}

	@media (max-width: 565px) {
		position: static;
		.profit-second__icon-item {
			.icon-wrapper {
				min-width: 36px;
				min-height: 36px;
				border-radius: 12px;

				.icon {
					min-width: 26px;
					min-height: 26px;
				}
			}

			.label {
				order: 2;
				width: 130px;
				font-size: 13px;
				padding: 0 0 0 20px;
			}
		}
	}

	@media (max-width: 380px) {
		.profit-second__icon-item {
			.label {
				padding: 0 0 0 10px;
			}
		}
	}
}
