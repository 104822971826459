@import '../../../assets/styles/colors';

.popup {
	&-plug {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		height: 100%;
		backdrop-filter: blur(16px);
		background-color: rgba(0, 0, 0, 0.5);
		border: none;
		cursor: default;
	}
}
