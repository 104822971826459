@import '../../../../../assets/styles/mixins';

.programm-tab-content {
	display: none;
	padding: 20px 0;

	&.visible {
		display: block;
	}

	.programm-tab-content-item + .programm-tab-content-item {
		margin-top: 42px;
	}

	@include max-width(768px) {
		.programm-tab-content-item {
			padding-bottom: 21px;
			& + .programm-tab-content-item {
				margin-top: 0;
				padding: 21px 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
