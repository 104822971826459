@import '../../assets/styles/mixins';

.icon {
	display: block;
	height: 100%;
	width: 100%;

	&.close {
		@include bg-image('svg/close.svg', center);
	}

	&.certificates {
		@include bg-image('svg/certificates.svg', center, auto);
	}

	&.upload-image {
		@include bg-image('svg/upload-file-icon.svg', center, auto);
	}

	&.notifications {
		@include bg-image('svg/notifications.svg', center, auto);
	}

	&.approved {
		@include bg-image('svg/approved.svg', center, auto);
	}

	&.denied {
		@include bg-image('svg/denied.svg', center, auto);
	}

	&.checkmark-white {
		@include bg-image('svg/checkmark-white.svg', center, auto);
	}

	&.close {
		@include bg-image('svg/close.svg', center, auto);
	}

	&.dropdown {
		@include bg-image('svg/dropdown.svg', center, auto);
	}

	&.dropdown-close {
		@include bg-image('svg/dropdown-close.svg', center, auto);
	}

	&.info {
		@include bg-image('svg/info.svg', center, auto);
	}

	&.social_vk {
		@include bg-image('svg/social-icons/social_vk.svg', center, auto);
	}

	&.social_fb {
		@include bg-image('svg/social-icons/social_fb.svg', center, auto);
	}

	&.social_tg {
		@include bg-image('svg/social-icons/social_tg.svg', center, auto);
	}

	&.social_yt {
		@include bg-image('svg/social-icons/social_yt.svg', center, auto);
	}

	&.social_inst {
		@include bg-image('svg/social-icons/social_inst.svg', center, auto);
	}

	&.social_in {
		@include bg-image('svg/social-icons/social_in.svg', center, auto);
	}

	&.social_habr {
		@include bg-image('svg/social-icons/social_hr.svg', center, auto);
	}

	&.social_github {
		@include bg-image('svg/social-icons/social_git.svg', center, auto);
	}

	&.social_web {
		@include bg-image('svg/social-icons/social_web.svg', center, auto);
	}

	&.social_behance {
		@include bg-image('svg/social-icons/social_be.svg', center, auto);
	}

	&.social_dribbble {
		@include bg-image('svg/social-icons/social_dr.svg', center, auto);
	}

	&.actived {
		@include bg-image('svg/actived.svg', center, auto);
	}

	&.accordion-arrow {
		@include bg-image('svg/accordion-arrow.svg', center, auto);
	}

	&.photo-placeholder {
		@include bg-image('svg/photo-placeholder.svg', center, auto);
	}
}
