@import '../../../assets/styles/mixins';
@import '../../../assets/styles/colors';
.event-page {
	width: 100%;
	@include max-width(1400px) {
		.right-column {
			width: 330px;
			margin-left: 20px;
			.event__buttons {
				display: none;
			}
		}
	}

	@include max-width(1024px) {
		.page-wrapper {
			padding: 20px 34px;
			.right-column {
				width: 100%;
				margin-left: 0;
				.panel {
					padding: 0;
					border: 0;
					margin-top: 30px;
					.event-programm-tabs {
						&::after {
							content: none;
						}
					}
					.programm-tab-content {
						border: 1px solid $color-panel-border;
						padding: 20px;
						border-radius: 8px;
					}
				}
			}
		}
	}
	@include max-width(768px) {
		.page-wrapper {
			.right-column {
				.panel {
					margin-top: 15px;
					.event-programm {
						&__title {
							font-size: 16px;
						}

						.programm-tab-content {
							border-radius: 0;
							background-color: $color-app-main;
							border: 0;
							border-top: 1px solid $color-panel-border;
							padding: 20px 0 25px;
						}
					}
				}
				.event__buttons {
					display: block;
					max-width: 320px;
					width: 100%;
					background-color: $color-app-main;
					margin: 0 auto 40px;
					border-bottom: 0;
					button {
						height: 48px;
					}
					.event__status {
						width: 100%;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}
