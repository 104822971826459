@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.notif-container {
	position: relative;
	height: 40px;
	width: 40px;

	@include max-width(480px) {
		position: inherit;
	}

	&.hover .drop-card {
		opacity: 1;
		z-index: 20;
		top: 40px;
	}

	.notif-button {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		height: 100%;
		width: 100%;
		background-color: $color-blue-ghost;
		border-radius: 50%;
		cursor: pointer;
		z-index: 2;

		.badge {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: -2px;
			right: -2px;
			height: 17px;
			width: 17px;
			background-color: $color-red;
			border: 2px solid $color-app-main;
			border-radius: 50%;

			span {
				color: $color-app-main;
				font-size: 10px;
				font-weight: 600;
			}
		}
	}

	.drop-card {
		position: absolute;
		top: 32px;
		right: 0;
		width: 360px;
		height: 350px;
		background-color: $color-app-main;
		box-shadow: $card-shadow;
		border-radius: 8px;
		opacity: 0;
		overflow-x: hidden;
		z-index: -1;
		transition: all 0.2s ease-out;

		@include max-width(480px) {
			width: 100%;

			&:hover .drop-card {
				border-radius: 0;
				box-shadow: none;
			}
		}

		&-plug {
			padding-top: 20px;
			padding-bottom: 20px;
			line-height: 24px;
			color: $color-text-black;
			text-align: center;
		}

		.scroll-wrapper {
			position: relative;
			max-height: 560px;
			width: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 0px;
			}
		}

		.list-container {
			display: flex;
			flex-direction: column;
			align-items: stretch;

			.list-item {
				display: flex;
				align-items: center;
				position: relative;
				min-height: 58px;
				margin: 8px 12px;
				padding: 4px;
				background-color: $color-grey-light;
				border-radius: 4px;
				cursor: pointer;

				&.checked {
					opacity: 0.4;
				}

				&:first-child {
					margin-top: 12px;
				}

				&:last-child {
					margin-bottom: 12px;
				}

				img {
					height: 64px;
					width: 64px;
					margin-right: 4px;
					border-radius: 4px;
					object-fit: cover;
				}

				.status {
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 51px;
					height: 24px;
					width: 24px;
					background-color: $color-grey-light;
					border-radius: 50%;
				}

				.text {
					margin: 0 12px;
					font-size: 14px;
				}
			}
		}
	}

	@include max-width(1024px) {
		.drop-card {
			.scroll-wrapper {
				max-height: 300px;
			}
		}
	}

	@include max-width(768px) {
		width: 25px;
		height: 25px;

		& > .notif-button {
			background-color: transparent;
		}
	}

	@include max-width(600px) {
		.drop-card {
			max-height: 300px;
		}
		&.hover {
			.drop-card {
				top: 33px;
			}
		}
	}

	@include max-width(480px) {
		&:hover .drop-card {
			border-radius: 0;
			box-shadow: none;
		}
	}
}
