@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.comment-block {
	display: flex;
	flex-wrap: wrap;
	padding: 16px;

	& + .comment-block {
		margin-top: 15px;
	}

	&.delete {
		border: 1px solid $color-input-error;
		border-radius: 8px;
	}

	&__avatar {
		min-width: 40px;
		width: 40px;
		height: 40px;
		margin-right: 16px;
		border-radius: 50%;
		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		max-width: calc(100% - 58px);
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 18px;

		&__name {
			p {
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 0;
			}
		}

		&__time {
			p {
				margin-bottom: 0;
				font-size: 14px;
				line-height: 20px;
				color: $color-input-placeholder;
			}
		}
	}

	&__text {
		width: 100%;
		p {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__buttons {
		width: 100%;
		margin-top: 20px;
		margin-left: 56px;
	}

	.delete-reason-text {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: $color-red;

		& > p {
			margin-top: 10px;
			font-size: 16px;
			line-height: 18px;
			color: $color-red;
		}
	}

	@include max-width(768px) {
		padding: 4px;

		& + .comment-block {
			margin-top: 5px;
		}
	}
}
