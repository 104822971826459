@import '../../../assets/styles/colors';

.certificate-modal-content-date {
	padding: 0 8px;
	font-weight: bold;
	font-size: 14px;
	line-height: 24px;
	color: $color-app-main;
	border-radius: 20px;
	background-color: $color-date-bg;
}
