@import '../../../assets/styles/colors';

.check-container {
	margin-bottom: 30px;
}

.regist-form {
	.phone-input-container {
		margin-bottom: 10px;
		font-family: inherit;

		.phone-input {
			padding: 13px 16px 13px 62px;
			height: auto;
			width: 100%;
			height: 48px;
			font-size: 16px;
			border-radius: 0;
			border: 1px solid $color-border;

			&:focus {
				border-color: $color-blue-focus;
			}
		}

		.phone-input-button {
			.selected-flag {
				width: 52px;
				padding-left: 14px;
			}
		}

		.phone-input-dropdown {
			box-shadow: none;
			border: 1px solid $color-border;
		}
	}

	.register__form {
		margin-top: 10px;
		font-size: 16px;

		&-button {
			border: 0;
			background-color: transparent;
			cursor: pointer;
			color: $color-blue;

			&:hover {
				color: $color-blue-focus;
			}
		}
	}

	&.checkin {
		.phone-input-container {
			margin-bottom: 30px;
		}
	}
}
