@import '../../../../../assets/styles/mixins';

.integrations-modal-title {
	display: flex;
	align-items: center;
	margin-bottom: 6px;
	font-weight: 500;
	font-size: 28px;
	line-height: 32px;
	letter-spacing: 0.04em;

	& > .title-icon {
		width: 28px;
		height: 28px;
		min-width: 28px;
		min-height: 28px;
		margin-left: 14px;

		& > i {
			display: block;
			width: 100%;
			height: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	@include max-width(768px) {
		font-size: 24px;
	}
} 
