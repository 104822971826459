@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.events-page {
	position: relative;
	display: flex;
	min-height: 100%;
	width: 100%;

	&.list {
		background: rgb(245, 247, 249);
	}

	&.loading {
		height: 100%;
	}

	.mobile-icons-block {
		display: none;
		margin-top: 10px;
	}

	@include max-width(1400px) {
		.adaptive.right-column {
			display: none;
		}

		.left-column {
			width: 100%;
			margin-bottom: 20px;
		}

		&.list .page-wrapper {
			.columns-container {
				width: calc(100% - 80px);
			}
		}
	}
	@include max-width(1024px) {
		// .adaptive.right-column {
		// 	display: block;
		// }
		&.list .page-wrapper {
			.columns-container {
				width: calc(100% - 60px);
			}
		}
	}

	@include max-width(768px) {
		.page-wrapper {
			padding: 0 0 16px;
		}

		.event-page {
			.left-column {
				margin-bottom: 0;
			}
		}

		&.list {
			.page-wrapper {
				.columns-container {
					width: 100%;
				}
			}
		}

		.mobile-icons-block {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: 0;
			padding: 0 8px;

			.calendar-image {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 44px;
				background-image: inherit;
				padding: 5px;

				i {
					width: 24px;
					height: 24px;
					@include bg-image('svg/nav-icons/calendar.svg', auto);
				}
			}

			.filter-image {
				display: none;
			}

			& > h2 {
				margin: 0;
			}

			& > .icons-block {
				display: flex;
				& > div {
					margin-bottom: 0;
					& + div {
						margin-left: 10px;
					}
				}
			}
		}

		.mobile__filter {
			padding: 0 8px 13px;
			overflow-x: scroll;
			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		.columns-container {
			width: 100%;
		}
	}
}
