.nav-links {
	display: flex;

	@media (max-width: 992px) {
		display: none;

		&.burger {
			display: flex;
			flex-direction: column;
			margin-top: 70px;
		}
	}
}
