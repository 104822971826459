.pagination {
	width: 10px;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);

	@media (max-width: 992px) {
		display: none;
	}
}
