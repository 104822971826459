@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.edit-questionnaire-link {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.04em;
	color: $color-blue-focus;

	& > i {
		display: block;
		width: 24px;
		height: 24px;
		margin-right: 8px;

		@include bg-image('svg/edit.svg', auto);
	}

	@include max-width(1400px) {
		& > i {
			margin-right: 0;
		}
		& > span {
			display: none;
		}
	}
	@include max-width(1400px) {
		& > i {
			margin-right: 8px;
		}
		& > span {
			display: inline-block;
			font-weight: 600;
			color: $color-blue-focus;
		}
	}
}
