@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.event {
	.go-back {
		padding: 11px;
	}

	&__image {
		position: relative;
		margin-bottom: 24px;
		width: 100%;
		padding-bottom: 45%;
		border-radius: 8px;
		background-size: cover;
		background-position: center;
	}

	&__places {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 8px 16px;
		background-color: $color-blue-focus;
		border-radius: 8px;
		border-top-left-radius: 0;
		opacity: 0.89;

		span {
			color: $color-app-main;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0.04em;
			opacity: 0.6;
		}

		span:nth-child(2) {
			opacity: 1;
		}
	}

	&__title {
		margin-bottom: 16px;
		font-weight: 500;
		font-size: 28px;
		line-height: 32px;
		letter-spacing: 0.04em;
	}

	&__info {
		line-height: 24px;
		opacity: 0.7;
		font-size: 16px;
		.event__places {
			display: none;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 26px 0 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid $color-border-light;

		.event__status {
			display: flex;
			align-items: center;
			flex: 2 1;
			flex-wrap: nowrap;
			margin-right: 8px;
			padding: 12px 12px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.04em;
			border-radius: 8px;

			i {
				display: block;
				margin-right: 8px;
				width: 24px;
				height: 24px;
			}

			&--moderating {
				color: $color-blue-focus;
				background-color: $color-blue-ghost;

				i {
					@include bg-image('svg/status-icons/eye-blue.svg', center);
				}
			}

			&--approved {
				color: $color-green;
				background-color: $color-green-ghost;

				i {
					@include bg-image('svg/approved.svg', center);
				}
			}

			&--rejected {
				background-color: $color-red;

				i {
					@include bg-image('svg/status-icons/close-white.svg', center);
				}

				span {
					color: $color-app-main;
					line-height: 24px;

					&:nth-child(2) {
						margin-right: 7px;
					}

					&:nth-child(3) {
						opacity: 0.8;
					}
				}
			}
		}

		& > .event-light-button {
			flex: 1 2;
		}
	}

	&__description {
		font-size: 16px;
		pre {
			line-height: 20px;
		}
		&--approve {
			margin-bottom: 15px;
		}
	}

	&__stream-button {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;
	}

	&__not-auth {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 60px;

		.not-auth__text {
			margin-bottom: 10px;
			padding: 0 15px;
			text-align: center;
		}
	}

	&__login-link {
		margin-top: 10px;

		a {
			color: $color-blue-focus;
			text-decoration: underline;
		}
	}

	@include max-width(1400px) {
		&__title {
			margin-bottom: 10px;
		}
		&__info {
			font-size: 16px;
		}
		&__buttons {
			padding: 10px 0 20px;
			.button-main.lg {
				padding: 0 58px;
			}
		}
		&__description {
			font-size: 16px;
		}
	}

	@include max-width(1024px) {
		&__buttons {
			padding: 24px 0 30px;
		}
	}

	@include max-width(768px) {
		.arrow-link {
			background-color: $color-grey-light;
		}

		&__image {
			display: none;
		}

		&__title,
		&__info,
		&__buttons {
			padding: 0 8px;
			background-color: $color-grey-light;
		}

		&__title {
			margin-bottom: 0;
			padding: 5px 40px 8px 8px;
			font-size: 20px;
			line-height: 27px;
		}

		&__info {
			padding-bottom: 15px;
			.event__places {
				position: static;
				display: table;
				border-radius: 20px;
				margin: 16px 0 5px;
				padding: 3px 16px;
				background-color: $color-blue-ghost;
				opacity: 1;
				span {
					color: $color-blue;
					font-size: 12px;
					opacity: 1;
				}
			}
		}

		&__buttons {
			margin-bottom: 26px;
			padding-bottom: 15px;
			align-items: inherit;

			& > button {
				width: 100%;
			}
		}

		&__description {
			font-size: 12px;
			padding: 0 8px 24px 8px;
			margin-top: 16px;
			border-bottom: 2px solid rgba(48, 50, 59, 0.1);

			pre {
				line-height: 16px;
			}

			&--approve {
				padding: 0 15px;
			}
		}
	}
}
