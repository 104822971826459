@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.regist-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-color: $color-app-background;

	&.text {
		justify-content: flex-start;
		overflow-y: scroll;
		padding-bottom: 45px;

		&::-webkit-scrollbar {
			width: 0;
		}

		.checkin-text {
			max-width: 640px;
			width: 100%;
			margin: 45px 0;
			font-size: 18px;
			text-align: center;
		}
	}

	.form-container {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		padding: 32px;
		max-width: 480px;
		background-color: $color-app-main;
		border: 1px solid $color-panel-border;
		border-radius: 8px;

		.landing-join {
			background-color: $color-app-main;

			.landing-inner {
				background: none;
			}
		}

		.langing-join {
			padding: 20px 0;
		}

		.langing-join__text {
			font-size: 16px;
		}

		.check-container {
			display: flex;

			.check-text {
				margin-left: 8px;
				font-size: 12px;
			}
		}
	}

	@include max-width(480px) {
		&.text {
			.checkin-text {
				max-width: 300px;
				width: 100%;
				font-size: 14px;
			}
		}

		.form-container {
			max-width: 300px;
			padding: 16px;
		}
	}
}
