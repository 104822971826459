@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.textarea-main {
  min-height: 164px;
  width: 100%;
  padding: 13px 16px;
  background-color: $color-app-main;
  border: 1px solid $color-border;
  outline: none;
  font-size: 16px;
  resize: none;
  transition: $transition-main;

  &::placeholder {
    color: $color-input-placeholder;
  }

  &:focus,
  &:not([value=""]) {
    padding-top: 20px;
    padding-bottom: 7px;

    & + label {
      top: 5px;
      font-size: 12px;
    }
  }

  &:focus {
    border-color: $color-blue-focus;
  }
}