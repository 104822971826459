.mobile__filter {
	width: 100%;

	& > .filters-header {
		width: 100%;
		justify-content: flex-start;
		gap: 10px;
		padding-left: 0;
	}
}
