@mixin shift-out($shift) {
  animation: fade .25s ease-out backwards;

  @keyframes fade {
    from {
      opacity: 0;
      transform: translateX($shift);
    }
  
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@mixin spin {
  animation: spin 1.25s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
}