@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.certificate-modal-content {
	position: relative;

	&__title {
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 28px;
		line-height: 32px;
		letter-spacing: 0.04em;
	}

	&__dates {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		span {
			line-height: 24px;
			color: #838592;
			font-size: 16px;
			// opacity: 0.7;

			&:first-child {
				margin-right: 8px;
			}
		}
	}

	&__address,
	&__conditions {
		line-height: 24px;
		font-size: 16px;

		p:first-child {
			color: #838592;
		}
		p {
			margin: 0;
			padding: 0;
			span {
				color: $color-text-black;
			}
		}
	}

	&__address {
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
	}

	&__qr-code {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;

		& > .qr-code {
			width: 228px;
			height: 228px;
			background-position: center;
			background-size: cover;
		}
	}

	&__code {
		display: flex;
		justify-content: center;
		margin-bottom: 46px;

		span:first-child {
			margin-right: 12px;
			line-height: 24px;
			opacity: 0.6;
		}

		span:nth-child(2) {
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.2em;
		}
	}

	&__used,
	&__rejected > .rejected-status {
		display: flex;
		align-items: center;
		margin-top: 175px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;

		i {
			display: block;
			width: 40px;
			height: 40px;
		}
	}

	&__used {
		color: $color-green;

		i {
			@include bg-image('svg/approved.svg', center);
		}
	}

	&__rejected {
		& > .rejected-status {
			margin-bottom: 10px;
			color: $color-red;

			i {
				@include bg-image('svg/status-icons/close-red.svg', center);
			}
		}

		& > .rejected-reason {
			font-size: 14px;
			line-height: 20px;
			opacity: 0.7;
		}
	}

	&.rejected {
		&::after {
			position: absolute;
			transform: rotate(-30deg) translateY(50%) translateX(50%);
			top: 38%;
			right: 50%;
			font-weight: 500;
			font-size: 100px;
			line-height: 100%;
			letter-spacing: 0.04em;
			color: $color-red;
			opacity: 0.7;
			content: 'Отменен';
		}
	}

	@include max-width(768px) {
		&.rejected {
			&::after {
				font-size: 72px;
				line-height: 100%;
			}
		}
		&__dates {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
