.sub-text {
	display: flex;
	flex-direction: column;
	margin-top: 7px;
	margin-bottom: 5px;
	font-size: 12px;

	.active-text + .active-text,
	.active-text + .message-timeout,
	.message-timeout + .message-timeout,
	.message-timeout + .active-text {
		margin-top: 8px;
	}
}
