@import '../../../../assets/styles/mixins';

.events-container {
	.events-item + .events-item {
		margin-top: 12px;
	}

	.events__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 28px;

		& > span {
			font-weight: 500;
			font-size: 20px;
			line-height: 100%;
			letter-spacing: 0.04em;
		}

		& > i {
			display: block;
			width: 24px;
			height: 24px;
			cursor: pointer;

			@include bg-image('svg/close.svg', center);
		}
	}

	.events__plug {
		margin: 50px auto 0;
		max-width: 360px;
		line-height: 24px;
		text-align: center;
	}

	.past-events {
		padding: 30px 0 20px;

		&__title {
			margin-bottom: 12px;
			font-weight: 500;
			font-size: 20px;
			line-height: 100%;
			letter-spacing: 0.04em;
		}
	}

	@include max-width(768px) {
		padding-bottom: 55px;
		.past-events {
			&__title {
				padding: 0 16px;
			}
		}
	}
}
