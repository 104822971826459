@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.app {
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
	background-color: $color-app-main;

	& > .container {
		flex: 1;
		height: calc(100% - 60px);
		display: flex;
		align-items: stretch;

		& > .page-container {
			flex: 1 1 100%;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
		}
	}

	.mobile-menu-bottom {
		position: fixed;
		display: flex;
		flex-wrap: wrap;
		bottom: 0;
		width: 100%;
		height: 52px;
		align-items: center;
		background-color: #fff;
		z-index: 10;
		box-shadow: 0px -12px 10px rgba(67, 72, 114, 0.02),
			0px -4px 4px rgba(67, 72, 114, 0.04);

		& > nav {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			padding: 0 10px;
			overflow-x: auto;
			mask-image: linear-gradient(
				90deg,
				transparent 0,
				rgba(0, 0, 0, 0.25) 9px,
				#000 18px,
				#000 calc(100% - 18px),
				rgba(0, 0, 0, 0.45) calc(100% - 9px),
				transparent
			);

			&::-webkit-scrollbar {
				display: none;
			}

			& > .nav-item {
				margin-top: 0;
				border-radius: 0;
				margin-bottom: 0;
				padding: 5px 10px;
				border: 0;
				filter: grayscale(1);
				flex-direction: column;
				justify-content: center;
				width: 100%;

				span {
					display: block;
					white-space: nowrap;
					font-weight: 500;
					font-size: 12px;
					line-height: 100%;
				}

				&.nav-item--active {
					border: 0;
					background-color: transparent;
					filter: grayscale(0);
				}

				& > .icon {
					margin-right: 0;
				}
			}
		}
	}
}
