@import '../../assets/styles/mixins';

.page-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 12px 16px 22px 32px;

	@include max-width(1400px) {
		padding: 12px 22px;
	}

	@include max-width(768px) {
		padding: 16px 15px 50px;
	}
}
