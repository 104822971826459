@import '../../assets/styles/colors';

.slider__wrapper {
	.popup-wrapper {
		width: 100%;
		max-width: 1152px;
		margin: 0 auto;
		background: transparent;
		border: none;

		.popup-close::before,
		.popup-close::after {
			background-color: $color-app-main;
		}
	}

	.main__slider {
		margin-bottom: 17px;

		.swiper-slide {
			width: 1032px;
			height: auto;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				object-fit: scale-down;
			}
		}
	}

	.slider__thumbs {
		max-height: 130px;

		img {
			width: 100%;
			height: auto;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		color: $color-app-main;
		width: 35px;
		height: 80px;
		transition: 0.3s;

		&::after {
			font-size: 1.5rem;
		}

		// &:hover{
		// 	color: $color-blue-focus;
		// }
	}

	.swiper-button-next {
		right: 0;
	}

	.swiper-button-prev {
		left: 0;
	}
}
