@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.arrow-link {
	display: flex;
	align-items: center;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.04em;
	color: $color-blue-focus;

	i {
		display: block;
		margin-left: 9px;
		width: 24px;
		height: 24px;

		@include bg-image('svg/link-arrow.svg', auto);
	}

	&:hover {
		i {
			@include bg-image('svg/link-arrow-black.svg', auto);
		}
	}

	&.go-back {
		& > i {
			transform: rotate(180deg);
			margin-left: 0;
			margin-right: 9px;
		}
	}
}
