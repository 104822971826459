@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.events-item {
	display: flex;
	justify-content: space-between;
	padding: 2px 16px 2px 2px;
	background-color: $color-app-main;
	border: 2px solid $color-border-light;
	border-radius: 8px;
	cursor: pointer;

	&__image {
		margin-right: 16px;
		width: 124px;
		min-width: 124px;
		height: 124px;
		min-height: 124px;
		background-position: center;
		background-size: cover;
		border-radius: 4px;
	}

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: auto;
		margin-left: 0;
		padding: 16px 0 10px;
	}

	&__title {
		color: $color-text-black;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
	}

	&__status {
		display: flex;

		.item-status {
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;

			i {
				display: block;
				margin-right: 4px;
				width: 24px;
				height: 24px;
			}

			&__moderating {
				color: $color-blue-focus;

				i {
					@include bg-image('svg/status-icons/eye-blue.svg', center);
				}
			}

			&__approved {
				color: $color-green;

				i {
					@include bg-image('svg/approved.svg', center);
				}
			}

			&__rejected {
				color: $color-red;

				i {
					@include bg-image('svg/status-icons/close-red.svg', center);
				}
			}

			&__count {
				padding: 4px 12px;
				color: $color-blue-focus;
				background-color: $color-blue-light-bg;
				border-radius: 20px;

				&--full {
					color: $color-text-black;
					background-color: $color-border-light;
				}
			}
		}
	}

	&__subinfo {
		align-self: flex-start;
		margin-left: 20px;
		padding: 16px 0;
		color: $color-text-black;
		line-height: 24px;
		text-align: right;
		opacity: 0.7;
		font-size: 16px;

		&-mobile {
			display: none;
		}

		.events-item__date {
			display: flex;
			flex-direction: column;
		}
	}

	&.past {
		opacity: 0.6;

		.events-item__status {
			.item-status {
				&__approved {
					padding: 2px 12px 2px 2px;
					color: $color-app-main;
					background-color: $color-green;
					border-radius: 20px;

					i {
						@include bg-image('svg/approved-white.svg', center);
					}
				}

				&__rejected {
					padding: 2px 12px 2px 2px;
					color: $color-app-main;
					background-color: $color-red;
					border-radius: 20px;

					i {
						@include bg-image('svg/status-icons/close-white.svg', center);
					}
				}
			}
		}
	}

	@include max-width(1400px) {
		max-width: 620px;
	}
	@include max-width(1024px) {
		max-width: 100%;
	}
	@include max-width(768px) {
		padding: 4px 16px;
		border-radius: 0;
		border: inherit;

		&__title {
			font-size: 16px;
		}
		&__info {
			padding: 10px 0;
		}

		&__image {
			display: none;
		}

		&__status {
			margin-top: 16px;

			& > .item-status {
				border-radius: 20px;
				padding: 4px 12px 4px 4px;
			}

			& > .item-status__approved {
				background-color: $color-green;
				color: $color-app-main;
				i {
					@include bg-image('svg/approved-white.svg', center);
				}
			}

			& > .item-status__rejected {
				background-color: $color-red;
				color: $color-app-main;
				i {
					@include bg-image('svg/status-icons/close-white.svg', center);
				}
			}

			& > .item-status__count {
				padding: 7px 12px 9px;
			}

			& > .item-status__moderating {
				padding: 3px 12px;
				border-radius: 20px;
				background-color: $color-blue-focus;
				color: #fff;

				& > i {
					@include bg-image('svg/status-icons/eye.svg', center);
				}
			}
		}

		&__subinfo {
			display: none;

			&-mobile {
				display: flex;
				margin-top: 10px;
				font-size: 12px;
				line-height: 16px;

				& > .events-item__details {
					margin-right: 5px;
				}
			}

			.events-item__date {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
