$transition-main: all 0.15s ease-in-out;

$card-shadow: 0px -8px 10px rgba(67, 72, 114, 0.0456112), 0px -2px 4px rgba(67, 72, 114, 0.07),
	0px 30px 24px rgba(67, 72, 114, 0.035), 0px 12px 10px rgba(67, 72, 114, 0.0456112),
	0px 4px 4px rgba(67, 72, 114, 0.07);

$button-blue-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15),
	0px 4px 16px rgba(62, 100, 200, 0.4);

$button-white-shadow: 0px 2px 4px rgba(55, 59, 79, 0.05), 0px 3px 8px rgba(55, 59, 79, 0.08),
	0px 4px 16px rgba(55, 59, 79, 0.2);

$button-red-shadow: 0px 2px 4px rgba(235, 87, 87, 0.1), 0px 3px 8px rgba(235, 87, 87, 0.15),
	0px 4px 16px rgba(235, 87, 87, 0.4);

$button-green-shadow: 0px 2px 4px rgba(52, 162, 109, 0.1), 0px 3px 8px rgba(52, 162, 109, 0.15);

//landing
$transition: 0.3s all ease-in-out;

$shadow: 3.64394px 5.01614px 16.9px rgba(0, 0, 0, 0.055),
	0.797112px 1.09728px 6.0125px rgba(0, 0, 0, 0.03575);

$big-shadow: 18.2197px 25.0807px 104px rgba(0, 0, 0, 0.11),
	9.22372px 12.6971px 45.3375px rgba(0, 0, 0, 0.07425),
	3.64394px 5.01614px 16.9px rgba(0, 0, 0, 0.055),
	0.797112px 1.09728px 6.0125px rgba(0, 0, 0, 0.03575);

$button-shadow-blue: 0px 5px 19px 3px rgba(93, 131, 228, 0.2),
	0px 5px 9px 4px rgba(93, 131, 228, 0.2);

$icon-shadow-blue: 0px 5px 19px 5px rgba(93, 131, 228, 0.2), 0px 5px 9px 7px rgba(93, 131, 228, 0.2);

$button-shadow-white: 0px 1px 14px 4px rgba(255, 255, 255, 0.2);

$button-shadow-blue-inset: 0px 1px 14px 1px rgba(93, 131, 228, 0.2);

$button-shadow-white-inset: 0px 1px 14px 1px rgba(255, 255, 255, 0.2);
