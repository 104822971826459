@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.calendar {
	.nice-dates-navigation {
		justify-content: flex-start;
		margin-bottom: 15px;

		&_previous {
			order: 1;
		}
		&_current {
			flex-grow: 0;
			order: 3;
			margin-left: 20px;
			font-weight: 500;
			font-size: 20px;
			line-height: 100%;
			letter-spacing: 0.04em;
		}
		&_next {
			order: 2;
		}

		&_previous,
		&_next {
			padding-bottom: 10px;
			width: 30px;
		}

		&_previous::before,
		&_next::before {
			width: 10px;
			height: 10px;
			margin-left: -5px;
			margin-top: -5px;
		}

		&_previous::after,
		&_next::after {
			position: absolute;
			top: -12px;
			width: 34px;
			height: 34px;
			border-radius: 50%;
			content: '';
		}

		&_previous:hover::after,
		&_next:hover::after {
			background-color: $color-blue-ghost;
		}

		&_previous::after {
			left: -4px;
		}

		&_next::after {
			left: 0;
		}
	}

	.nice-dates-week-header {
		&_day {
			color: $color-text-black;
			text-transform: uppercase;
			font-size: 12px;
		}
	}

	.nice-dates-grid {
		&_container {
			.nice-dates-day {
				color: $color-text-black;
				font-size: 16px;

				&.-disabled,
				&.-outside {
					.nice-dates-day_date {
						opacity: 0.6;
					}
				}

				&.-outside.-selected {
					.nice-dates-day_date {
						opacity: 1;
					}
				}

				&.-selected.-disabled {
					border: 2px solid $color-blue-focus;
					border-radius: 50%;

					&::before {
						background-color: $color-blue-ghost;
					}

					.nice-dates-day_date {
						color: $color-blue-focus;
					}
				}

				&::after {
					border: 2px solid $color-blue-focus;
				}

				&.-rejected:not(.-selected)::before {
					background-color: $color-red;
					opacity: 1;
				}

				&.-rejected:not(.-selected)::after {
					border: 2px solid $color-red;
				}

				&.-moderating:not(.-selected) {
					.nice-dates-day_date {
						color: $color-app-main;
					}
				}

				&.-moderating:not(.-selected)::before {
					background-color: $color-blue-focus;
					opacity: 1;
				}

				&.-approved:not(.-selected),
				&.-rejected:not(.-selected) {
					.nice-dates-day_date {
						color: $color-app-main;
					}
				}

				&.-approved:not(.-selected)::before {
					background-color: $color-green;
					opacity: 1;
				}

				&.-approved:not(.-selected)::after {
					border: 2px solid $color-green;
				}

				&.-event:not(.-selected)::before {
					background-color: $color-blue-ghost;
					opacity: 1;
				}

				&.-selected,
				&.-rejected,
				&.-moderating,
				&.-approved {
					.nice-dates-day_date {
						font-weight: 500;
					}
					.nice-dates-day_month {
						color: $color-app-main;
					}
				}
			}
		}
	}

	@include max-width(1400px) {
		padding: 22px 12px;
		.nice-dates-navigation {
			margin-bottom: 27px;
			margin-left: 8px;
			&_current {
				margin-left: 10px;
			}
		}
		.nice-dates-week-header {
			box-shadow: none;
			padding: 8px 0 5px;
		}
	}

	@include max-width(768px) {
		padding: 0;
	}
}
