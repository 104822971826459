// Commissioner
@font-face {
	font-family: 'Commissioner';
	src: local('Commissioner'), url('./fonts/Commissioner/Commissioner-Medium.woff2') format('woff2'),
		url('./fonts/Commissioner/Commissioner-Medium.woff') format('woff'),
		url('./fonts/Commissioner/Commissioner-Medium.ttf');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Commissioner';
	src: local('Commissioner'),
		url('./fonts/Commissioner/Commissioner-SemiBold.woff2') format('woff2'),
		url('./fonts/Commissioner/Commissioner-SemiBold.woff') format('woff'),
		url('./fonts/Commissioner/Commissioner-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Commissioner';
	src: local('Commissioner'), url('./fonts/Commissioner/Commissioner-Regular.woff2') format('woff2'),
		url('./fonts/Commissioner/Commissioner-Regular.woff') format('woff'),
		url('./fonts/Commissioner/Commissioner-Regular.ttf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// Montserrat

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-900.ttf');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-800.ttf');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-700.ttf');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-600.ttf');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-500.ttf');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-400.ttf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
