@import '../../../../../../../assets/styles/_colors.scss';
@import '../../../../../../../assets/styles/_variables.scss';

.join-us__icons-block-item {
	position: relative;
	display: block;
	width: 20px;
	height: 20px;
	margin-right: 30px;
	transition: $transition;

	&::before {
		content: '';
		position: absolute;
		display: block;
		top: 25%;
		left: 25%;
		width: 60%;
		height: 60%;
		border-radius: 50%;
		box-shadow: $icon-shadow-blue;
		opacity: 0;
	}

	&:hover {
		transform: translateY(1px);

		&::before {
			opacity: 1;
		}
	}

	& > i {
		display: block;
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}
