@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.checkbox-main {
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  cursor: pointer;

  input {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
  
    &:checked
    + span {
      background-color: $color-blue;
      border-color: $color-blue;
  
      &:hover {
        background-color: $color-blue-focus;
        border-color: $color-blue-focus;
      }
    }
  }

  span {
    display: block;
    height: 100%;
    width: 100%;
    background-color: $color-app-main;
    border: 1px solid $color-border;
    border-radius: 4px;
    transition: $transition-main;
  
    &:hover {
      border-color: $color-blue-focus;
    }
  }
}