@import '../../../../assets/styles/mixins';

.left-column {
	flex: 1;
	min-width: 300px;

	@include max-width(1024px) {
		flex: 1 1 100%;
		max-width: 100%;
		width: 100%;
	}
}
