@import '../../assets/styles/colors';

.landing {
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	height: 100%;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-thumb {
		display: none;
	}

	* {
		font-family: Montserrat, sans-serif !important;
		text-decoration: none;
		list-style: none;

		&::-webkit-scrollbar {
			display: none;
		}

		&::-webkit-scrollbar-thumb {
			display: none;
		}

		overflow-wrap: normal;
		word-wrap: normal;
		-webkit-hyphens: none;
		-ms-hyphens: none;
		-moz-hyphens: none;
		hyphens: none;
	}

	button {
		outline: none;
		background-color: transparent;
		cursor: pointer;
	}

	.main-section__wrapper {
		margin-top: -80px;
	}

	.about-secton__wrapper,
	.profit-second-secton__wrapper,
	.partners-secton__wrapper,
	.partner-secton__wrapper {
		background: linear-gradient(101.05deg, #5d83e4 -8.74%, #6d3ab8 112.46%),
			linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 18.06%);
	}

	@media (max-width: 768px) {
		.title {
			font-size: 27px;
		}

		.text {
			font-size: 14px;
		}

		.subtitle {
			font-size: 20px;
		}
	}

	@media (max-width: 350px) {
		.title {
			font-size: 22px;
			line-height: 120%;
		}

		.text {
			font-size: 12px;
		}

		.subtitle {
			font-size: 18px;
		}
	}
}
