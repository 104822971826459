@import '../../assets/styles/mixins';

.modal-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0);
	backdrop-filter: blur(0);
	transition: all 0.25s ease-out;
	visibility: hidden;
	z-index: 999;

	&.show {
		background: rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(16px);
		visibility: visible;
		.modal-container {
			opacity: 1;
			transform: translateY(0);
			visibility: visible;
		}
	}

	.modal-container {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		position: relative;
		width: 100%;
		max-width: 450px;
		max-height: 85%;
		padding: 40px;
		background-color: #fff;
		border-radius: 8px;
		opacity: 0;
		transform: translateY(16px);
		transition: all 0.25s ease-out;
		visibility: hidden;

		.users-filters-wrapper {
			margin-top: 10px;
			& > .filters {
				.filter__items {
					max-height: 300px;
					overflow-y: auto;
				}
			}
		}

		& > .preloader-component {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.btn-close {
			position: absolute;
			top: 12px;
			right: 12px;
			z-index: 1;
		}

		& > .save-avatar {
			margin-top: 25px;
		}

		.button-block {
			display: flex;
			width: 100%;
			justify-content: flex-end;

			& > .acceptFilter {
				height: 38px;
				margin-top: 25px;
			}
		}
	}

	&.certificate {
		.modal-container {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			&::after {
				position: absolute;
				bottom: -4px;
				left: 0;
				width: 100%;
				height: 8px;
				background: linear-gradient(-45deg, #0000 6px, #fff 7px),
					linear-gradient(45deg, #0000 6px, #fff 7px);
				background-size: 10px 50%;
				background-position: 0 100%, 0 100%, 0 0, 0 0;
				background-repeat: repeat-x;
				content: '';
			}
		}
	}

	@include max-width(768px) {
		.modal-container {
			margin: 0 8px;
			padding: 40px 20px;
		}
	}

	@include max-width(375px) {
		.modal-container {
			padding: 20px;
		}
	}
}
