@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.panel {
	position: relative;
	padding: 20px;
	background: $color-app-main;
	border: 1px solid $color-panel-border;
	border-radius: 8px;
	transition: 0.15s;

	&.disabled {
		opacity: 0.4;

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
		}
	}

	&.right {
		position: fixed;
		z-index: 8;
		right: 60px;
		width: 332px;
		height: 100vh;
		border: 0;
		border-radius: 0;
		border-right: 1px solid $color-panel-border;
		border-left: 1px solid $color-panel-border;
		transform: translateX(400px);
	}

	&.open {
		transform: translateX(0);
	}

	@include max-width(1400px) {
		&.right {
			padding: 0;
		}
	}

	@include max-width(768px) {
		padding: 0 0 24px 0;
		border: none;
		border-radius: 0;

		& + .panel {
			border-top: 1px solid $color-panel-border;
		}
	}

	.calendar-image {
		width: 24px;
		height: 24px;
		margin-bottom: 10px;
		@include bg-image('svg/nav-icons/calendar.svg', auto);
		cursor: pointer;
	}

	.filter-image {
		width: 24px;
		height: 24px;
		margin-bottom: 10px;
		@include bg-image('svg/clear.svg', center);
		cursor: pointer;
	}
}

.panel-block-main-window {
	position: absolute;
	width: calc(100% - 120px);
	height: 100%;
	z-index: 7;
}
