@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.event-map {
	margin-bottom: 20px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;

	&__container {
		width: 100%;
		height: 212px;
	}

	&__info {
		padding: 12px 12px 20px;
		border-right: 1px solid $color-border-light;
		border-bottom: 1px solid $color-border-light;
		border-left: 1px solid $color-border-light;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&__title {
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
		&-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__popup {
		border: 0;
		background-color: transparent;
		color: $color-blue;
		font-size: 14px;
		cursor: pointer;
	}
	.modal-container {
		padding: 0;
		max-width: 100%;
	}
	.popup-map__container {
		width: 100%;
		height: 700px;
	}

	&__address {
		font-size: 14px;
		line-height: 20px;
		opacity: 0.7;
	}

	@include max-width(1024px) {
		display: flex;
		flex-direction: column-reverse;
		&__container {
			border-radius: 8px;
			overflow: hidden;
		}
		&__title {
			margin-bottom: 13px;
		}
		&__info {
			border: 0;
			padding: 12px 0 7px;
		}
		&__address {
			max-width: 280px;
		}
	}

	@include max-width(768px) {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		padding: 0 8px;
		margin-bottom: 0;
		&__container {
			height: 145px;
		}

		&__title {
			font-size: 16px;
			margin-bottom: 0;
		}

		&__mobile-title {
			margin: 10px 0;
			font-size: 16px;
		}

		&__info {
			display: none;
		}

		&__mobile-items {
			margin-top: 12px;
		}

		&__mobile-address-comment {
			font-size: 12px;
		}

		&__mobile-city {
			width: 100%;
			font-weight: 500;
			font-size: 12px;
			max-width: 250px;
		}
	}
}
