@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.active-text {
	color: $color-blue;
	cursor: pointer;
	transition: color 0.15s ease-in-out;

	&:hover {
		color: $color-blue-focus;
	}
}
