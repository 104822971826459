@import "../../../../assets/styles/_colors.scss";
@import "../../../../assets/styles/_mixins.scss";

.main-section {
  display: grid;
  grid-template-rows: 1fr 70px;
  grid-template-columns: 1fr 1fr;
  padding: 0 100px 30px 100px;
  height: 100%;
  @include bg-image("/landing/bg_main.png");

  @media (max-width: 1530px) {
    padding: 0 30px 30px 30px;
  }

  @media (max-width: 992px) {
    grid-template-rows: 1fr 100px;
    padding: 100px 45px 50px;
  }

  @media (max-width: 768px) {
    grid-template-rows: 1fr 135px;
    padding: 100px 30px 50px 30px;
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 90px 30px 30px 30px;
  }

  @media (max-width: 340px) {
    padding: 90px 15px 15px 15px;
  }
}
