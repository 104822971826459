.bot-integrations.modal {
	.modal-wrapper:first-child.show {
		background: rgba(0, 0, 0, 0.2);
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);

		.modal-container {
			.btn-close {
				display: none;
			}
		}
	}
}
