.section {
  display: block;
  width: 100%;
  min-height: 100vh;
  height: fit-content;

  // &.multiple {
  //   height: fit-content;
  // }

  @media (max-width: 992px) {
    &.main-section__wrapper {
      min-height: auto;
    }
  }

  // @media (max-width: 992px) {
  //   &.profit-secton-fourth__wrapper {
  //     min-height: 100vh;
  //     height: fit-content;
  //   }
  // }

  @media (max-width: 567px) {
    &.profit-secton__wrapper,
    &.profit-secton-fourth__wrapper,
    &.faq-secton__wrapper,
    &.contacts-secton__wrapper {
      min-height: auto;
    }
  }

  @media (max-width: 420px) {
    min-height: auto;
  }
}
