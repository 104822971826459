@import '../../assets/styles/mixins';
@import '../../assets/styles/colors';

.comments-block {
	&__title {
		margin-bottom: 20px;
		padding-bottom: 12px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
		border-bottom: 1px solid #e3e5e7;
	}

	&__comments {
		max-height: 600px;
		margin-top: 15px;
		overflow-y: auto;
	}

	.attention-text {
		font-size: 18px;
	}

	.show-all {
		margin-top: 25px;
		color: $color-blue;
		font-size: 16px;
		line-height: 24px;
		cursor: pointer;
	}

	@include max-width(768px) {
		padding-bottom: 60px;

		&__title {
			padding: 0 10px 12px 10px;
			border-bottom: 2px solid #e3e5e7;
		}

		&__comments {
			padding: 10px;
		}

		.show-all {
			padding: 10px;
		}

		.attention-text {
			padding: 10px;
		}
	}
}
