@import '../../../assets/styles/mixins';
@import '../../../assets/styles/colors';

.app-side-menu {
	z-index: 9;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 414px;
	padding: 40px 20px 0 30px;
	background-color: $color-app-main;
	box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
	transition: 0.15s;

	&.right {
		position: fixed;
		right: 0;
		display: flex;
		width: 60px;
		height: 100%;
		transform: translateX(60px);
	}

	&__link {
		margin-top: auto;
		margin-bottom: 0;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 16px;
		line-height: 24px;
		color: $color-text-black;
		opacity: 0.6;
		&.active {
			color: $color-text-black;
		}
		&:hover {
			color: $color-text-black;
		}
	}

	@include max-width(1400px) {
		width: 60px;
		padding: 0 0 26px;

		&.right {
			transform: translateX(0);
			padding: 13px 0 26px;
			align-items: center;
			.calendar-image {
				margin-bottom: 3px;
			}
		}

		& > .profile-block {
			display: none;
		}

		&__link {
			display: none;
		}
	}

	@include max-width(768px) {
		position: absolute;
		z-index: 100;
		transform: translateX(-100%);
		box-shadow: 0px 4px 32px transparent;
		height: calc(100% - 40px);
		width: 100%;
		background-color: #f5f7f9;

		&.mobileMenu {
			transform: translateX(0);
			// box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
		}

		&__link {
			display: inline;
			text-align: center;
		}

		&.right {
			display: none;
		}
	}
}
