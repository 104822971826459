@import '../../../../assets/styles/_colors.scss';

.contacts-section {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	padding: 100px 100px 0 100px;
	height: 100%;

	&::after {
		content: '';
		position: absolute;
		width: 1200px;
		height: 1200px;
		top: 0;
		left: -15%;
		background-color: $color-landing-ligth-gray;
		border-radius: 50%;
		z-index: -1;
	}

	&__info-wrapper {
		display: flex;
		align-items: center;
		flex-grow: 1;
		width: 100%;
	}

	&__map-block {
		display: flex;
		align-items: center;
		width: 50%;

		& > img {
			width: 100%;
		}
	}

	&__info-block {
		width: 50%;
	}

	&__info-title {
		margin-bottom: 35px;
		color: $color-landing-main-text;
	}

	&__info-contacts {
		display: flex;
		flex-direction: column;
		max-width: 375px;

		& > div {
			display: flex;
			margin-bottom: 15px;
		}

		i {
			display: block;
			min-height: 25px;
			min-width: 25px;
			background-repeat: no-repeat;
			margin-right: 10px;
		}

		span {
			color: $color-landing-dark-gray-text;
		}
	}

	@media (max-width: 1400px) {
		padding: 30px;
	}

	@media (max-width: 576px) {
		&__info-wrapper {
			display: flex;
			flex-direction: column;
			margin-top: 50px;
		}

		&__map-block {
			width: 100%;
		}

		&__info-block {
			width: 100%;
		}

		.contacts-section__info-contacts {
			& > div {
				margin-bottom: 20px;
			}
			.join-us__icons-block {
				margin-top: 20px;
			}
		}
	}
}
