@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.filters {
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 33px;

		&__title {
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.04em;
		}

		&__reset {
			display: flex;
			align-items: center;
			padding: 6px 12px;
			font-size: 14px;
			line-height: 20px;
			border-radius: 20px;
			cursor: pointer;

			&:hover {
				background-color: $color-blue-ghost;
			}

			i {
				display: block;
				margin-right: 4px;
				width: 24px;
				height: 24px;

				@include bg-image('svg/clear.svg', center);
			}
		}
	}

	&-body {
		.filter + .filter {
			margin-top: 22px;
		}

		display: flex;
		flex-direction: column;

		.filter__subtitle {
			font-weight: 400;
			font-size: 12px;
			line-height: 24px;
			opacity: 0.5;
			margin-bottom: 10px;
		}
	}

	@include max-width(1400px) {
		padding: 15px 20px;
		&-header {
			margin-bottom: 25px;
			&__reset {
				padding: 6px 0px 6px 12px;
			}
		}
	}

	@include max-width(768px) {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 0 8px 15px;
		overflow-x: scroll;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
			background-color: $color-app-main;
		}
		&-header {
			margin-bottom: 0;
			&__title {
				display: none;
			}
			&__reset {
				border: 1px solid #d7d9dc;
				background-color: $color-app-main;
				border-radius: 50%;
				padding: 4px;
				margin-right: 8px;
				i {
					margin-right: 0;
					width: 22px;
					height: 22px;
				}
				span {
					display: none;
				}
			}
		}
		&-body {
			flex-direction: row;
			gap: 4px;
			.filter + .filter {
				margin-top: 0;
			}
			.filter__title {
				display: none;
			}
			.filter__items {
				flex-wrap: nowrap;
				.filter-item {
					background-color: $color-app-main;
					border: 1px solid #d7d9dc;
					padding: 7px 12px;
					white-space: nowrap;
					&.active {
						background-color: #d7e0f5;
						border: 1px solid #d7e0f5;
					}
				}
			}
		}
	}
}
