.filter {
	&.hide {
		display: none;
	}
	&__title {
		margin-bottom: 10px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.04em;
		font-size: 16px;
	}

	&__items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 4px;
	}
}
