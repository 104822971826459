@import './../../../assets/styles/colors';
@import './../../../assets/styles/mixins';

.comments-block {
	&__body {
		display: flex;
		flex-wrap: wrap;
	}

	&__textarea-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		& > button {
			margin-top: 15px;
		}
	}

	.error-message {
		width: calc(100% - 250px);
		margin-top: 15px;
		color: $color-red;
		font-size: 14px;
		font-weight: 600;
	}

	@include max-width(768px) {
		&__body {
			padding: 10px;
		}

		&__textarea-block {
			justify-content: center;

			& > button {
				width: 100%;
				margin-top: 15px;
			}
		}

		.error-message {
			width: 100%;
		}
	}
}
