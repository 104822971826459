@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/variables';

.programm-tab {
	padding-bottom: 10px;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	opacity: 0.7;
	border-bottom: 2px solid transparent;
	cursor: pointer;
	transition: $transition-main;

	&.active {
		color: $color-blue-focus;
		border-bottom: 2px solid $color-blue-focus;
		opacity: 1;
	}

	&:hover {
		color: $color-blue-focus;
		opacity: 1;
	}
}
