@import '../../../../../assets/styles/_colors.scss';

.main {
	&__join-us {
		grid-column: 1/2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.join-us {
			&__title {
				line-height: 45px;
				text-transform: uppercase;
				color: $color-landing-blue;
				margin-bottom: 25px;
			}

			&__subtitle {
				margin-bottom: 25px;

				&,
				.bold {
					color: $color-landing-main-text;
				}
			}
		}
	}

	@media (max-width: 992px) {
		&__join-us {
			.join-us {
				&__title {
					br {
						display: none;
					}
				}
			}
		}
	}

	@media (max-width: 768px) {
		&__join-us {
			.join-us {
				&__title {
					font-size: 27px;
				}

				&__subtitle.text {
					font-size: 14px;
				}
			}
		}
	}

	@media (max-width: 576px) {
		&__join-us {
			.join-us {
				&__title {
					margin-bottom: 10px;
				}

				&__subtitle {
					margin-bottom: 20px;
				}
				&__content-block {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}
