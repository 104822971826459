@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.avatar {
	position: relative;

	&__image {
		position: relative;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		&-blur {
			display: none;
		}

		&--empty {
			background-color: $color-grey-light;
		}

		&.plug {
			background-size: 80%;

			&-0 {
				background-color: $color-avatar-coral;
			}
			&-2 {
				background-color: $color-avatar-lagoon;
			}
			&-3 {
				background-color: $color-avatar-gray;
			}
			&-4 {
				background-color: $color-avatar-purple;
			}
			&-5 {
				background-color: $color-avatar-mustard;
			}
			&-6 {
				background-color: $color-avatar-lavender;
			}
			&-7 {
				background-color: $color-avatar-mint;
			}
			&-8 {
				background-color: $color-skill-noob;
			}
			&-9 {
				background-color: $color-avatar-mint;
			}
			&-1 {
				background-color: $color-avatar-lagoon;
			}
		}
	}

	&__dropdown {
		position: absolute;
		top: 40px;
		right: 0;
		padding: 16px;
		background-color: $color-app-main;
		box-shadow: 0px -8px 10px rgba(67, 72, 114, 0.0456112), 0px -2px 4px rgba(67, 72, 114, 0.07),
			0px 30px 24px rgba(67, 72, 114, 0.035), 0px 12px 10px rgba(67, 72, 114, 0.0456112),
			0px 4px 4px rgba(67, 72, 114, 0.07);
		border-radius: 8px;
		visibility: hidden;
		opacity: 0;
		z-index: 100;
		transition: all 0.2s ease-out;

		&.show {
			top: 44px;
			opacity: 1;
			visibility: visible;
		}

		& > .dropdown__list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			.dropdown__list-item {
				display: flex;
				align-items: center;
				white-space: nowrap;
				cursor: pointer;

				& > i {
					display: block;
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}

				&:nth-child(1) {
					& > i {
						@include bg-image('svg/profile.svg', auto);
					}
				}

				&:nth-child(2) {
					& > i {
						@include bg-image('svg/edit.svg', auto);
					}
				}

				&:nth-child(3) {
					& > i {
						@include bg-image('svg/logout.svg', auto);
					}
				}
			}

			.dropdown__list-item + .dropdown__list-item {
				margin-top: 10px;
			}
		}
	}

	&.large {
		.avatar__image {
			width: 96px;
			height: 96px;
			box-shadow: 0 2px 8px rgba(56, 70, 83, 0.2);
		}
	}

	&.small {
		.avatar__image {
			width: 40px;
			height: 40px;
		}
	}

	&.plug {
		background-size: 80%;

		&-0 {
			background-color: $color-avatar-coral;
		}
		&-2 {
			background-color: $color-avatar-lagoon;
		}
		&-3 {
			background-color: $color-avatar-gray;
		}
		&-4 {
			background-color: $color-avatar-purple;
		}
		&-5 {
			background-color: $color-avatar-mustard;
		}
		&-6 {
			background-color: $color-avatar-lavender;
		}
		&-7 {
			background-color: $color-avatar-mint;
		}
		&-8 {
			background-color: $color-skill-noob;
		}
		&-9 {
			background-color: $color-avatar-mint;
		}
		&-1 {
			background-color: $color-avatar-lagoon;
		}
	}
}
