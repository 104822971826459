@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.page-404 {
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: $color-app-main;
	@include bg-image('img/big-shadow-x.png', left, contain);
	z-index: 100;

	&__header {
		position: absolute;
		top: 14px;
		left: 156px;
		&-logo {
			width: 88px;
			height: 17px;
			@include bg-image('svg/x-cluster-string.svg', center);
		}
	}

	&__left-column {
		width: 50%;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		&-vincent {
			@include bg-image('img/vincent-vega.png', bottom, contain);
			height: 520px;
			width: 505px;
		}
    }
    

	&__right-column {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		margin-left: 16px;

		&-title {
			span {
				font-weight: 600;
				font-size: 120px;
				letter-spacing: 0.04em;
				color: $color-blue-focus;
			}
		}

		&-subtitle {
			span {
				font-weight: 500;
				font-size: 28px;
				line-height: 32px;
			}
			margin-bottom: 8px;
		}

		&-text {
			span {
				font-size: 16px;
				line-height: 24px;
			}
			margin-bottom: 24px;
			max-width: 356px;
		}

		&-link {
			span {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	@include max-width(768px) {
		background-size: cover;

		&__header {
			transform: translateX(-50%);
			left: 50%;
		}

		&__left-column {
			position: absolute;
			bottom: 0;
			width: 100%;

			&-vincent {
				width: 100%;
				height: 300px;
			}
		}

		&__right-column {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-left: 16px;
			margin-bottom: 300px;

			&-title {
				span {
					font-size: 95px;
				}
			}

			&-subtitle {
				span {
					font-size: 22px;
				}
				margin-bottom: 8px;
			}

			&-text {
				span {
					font-size: 14px;
					line-height: 120%;
				}
				margin-bottom: 24px;
			}

			&-link {
				span {
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}
}
