@import '../../../../../assets/styles/_colors.scss';

.profit-first {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 70px 100px;
	height: 100%;
	overflow: hidden;

	&__marquee-wrapper {
		grid-row: 1;
		grid-column: 1/3;
		width: calc(100% + 200px);
		margin-left: -100px;
	}

	&__marquee {
		display: flex;
		width: 200%;
		animation: marquee-text 15s linear infinite;

		&-item {
			opacity: 0.2;
			font-weight: 800;
			font-size: 65px;
			text-transform: uppercase;
			color: transparent;
			-webkit-text-stroke: 1px $color-landing-main-text;
			white-space: nowrap;
			width: 25%;
		}
	}

	&__text-wrapper {
		grid-row: 2;
		grid-column: 1;
		justify-self: center;
		max-width: 495px;

		.profit-first__text-title {
			margin-bottom: 25px;
			color: $color-landing-main-text;
		}

		.bold {
			color: $color-landing-main-text;
			margin-top: 25px;
		}

		.profit-first__text {
			color: $color-landing-main-text;

			& > ul > li {
				position: relative;
				padding-left: 30px;
				color: $color-landing-main-text;

				&::before {
					content: ' ';
					position: absolute;
					display: block;
					left: 12px;
					top: 11px;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background: $color-landing-main-text;
				}
			}
		}
	}

	&__image-wrapper {
		& > img {
			transform: translate(10%, -7%);
		}
	}

	@media (max-width: 1625px) {
		&__marquee-wrapper {
			width: calc(100% + 170px);
			margin-left: -85px;
		}

		&__marquee {
			display: flex;
			width: 200%;
			animation: marquee-text 8s linear infinite;

			&-item {
				width: 100%;
				&:nth-child(odd) {
					display: none;
				}
			}
		}
	}

	@media (max-width: 1400px) {
		padding: 85px;

		&__image-wrapper {
			& > img {
				width: 100%;
			}
		}
	}

	@media (max-width: 1200px) {
		padding: 30px;

		&__marquee {
			&-item {
				font-size: 57px;
			}
		}

		&__text-wrapper {
			padding-top: 40px;
		}
	}

	@media (max-width: 992px) {
		&__text-wrapper {
			padding-top: 20px;
		}
	}

	@media (max-width: 768px) {
		padding: 50px 30px 10px 30px;

		&__text-wrapper {
			padding: 0px 10px 0 0;
		}

		&__marquee {
			&-item {
				font-size: 37px;
			}
		}

		&__image-wrapper {
			& > img {
				transform: scale(1.3) translate(10%, 5%);
			}
		}
	}

	@media (max-width: 576px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: fit-content;

		&__image-wrapper {
			& > img {
				transform: scale(1.3) translate(10%, 10%);
			}
		}
	}

	@media (max-width: 360px) {
	}
}

@keyframes marquee-text {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(calc(-50%));
	}
}
