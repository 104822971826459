@import '../../assets/styles/mixins';
@import '../../assets/styles/colors';

.accept-certificate-page {
	.header-block {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 26px;
		background-color: $color-app-main;
		border-bottom: 1px solid $color-border-light;

		&__icon {
			display: block;
			width: 120px;
			height: 60px;
			@include bg-image('svg/mobile-menu/mobile-menu-logo.svg', center, contain);

			a {
				display: block;
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
		}
	}

	.body-block {
		display: flex;
		height: calc(100vh - 61px);
		width: 100%;
		max-width: 600px;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		padding: 0 15px;

		&__container {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;
		}

		&__accept-block {
			display: flex;
			width: 100%;
		}

		h2 {
			margin-bottom: 30px;
		}

		.input-main-container {
			margin-right: 20px;
		}

		.accept-block {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			& > .activated {
				width: 40%;
				height: 40px;
				margin-bottom: 20px;
				@include bg-image('svg/actived.svg', center, contain);
			}

			& > p {
				font-size: 20px;
				font-weight: 600;
			}
		}
	}

	@include max-width(600px) {
		.body-block {
			&__accept-block {
				flex-wrap: wrap;

				& > .input-main-container {
					margin-right: 0;
					margin-bottom: 15px;
				}

				& > button {
					width: 100%;
				}
			}
		}
	}
}
