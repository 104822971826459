@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/colors';

.event-programm {
	&__title {
		margin-bottom: 22px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
	}

	@include max-width(768px) {
		margin-bottom: 0;

		&__title {
			margin-bottom: 0;
			padding: 15px 15px 22px 15px;
			background-color: $color-grey-light;
		}

		&-tabs {
			padding: 0 15px 0 15px;
			background-color: $color-grey-light;
		}
	}
}
