.player-modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(16px);
	transition: all 0.25s ease-out;
	visibility: hidden;
	cursor: pointer;

	&.show {
		visibility: visible;
	}
}
