$color-app-main: #ffffff;
$color-app-background: #f4f4f4;
$color-landing-background: linear-gradient(180deg, #ececec 0.01%, #ffffff 100%);
$color-landing-gray: #f6f6f6;
$color-gray-background: #f8f9fa;

$color-border-light: #e3e5e7;
$color-border: #d9d8dc;
$color-panel-border: #30323b26;

$color-text-black: #505365;
$color-input-placeholder: #959595;
$color-input-error: #ff8d8d;

$color-button-disabled: #cdcdcd;

$color-blue: #426edf;
$color-blue-focus: #5d83e4;
$color-blue-ghost: #5d83e41a;
$color-red: #eb5757;
$color-red-focus: #fd4747;
$color-red-ghost: #eb57571a;
$color-grey-light: #f5f7f9;
$color-green: #34a26d;
$color-green-focus: #4aac7d;
$color-green-ghost: #4aac7d1a;
$color-yellow: #ffcb13;
$color-yellow-ghost: #ffcb131a;

$color-skill-none: #b9bbc0;
$color-skill-noob: #f2c94c;
$color-skill-junior: #f2994a;
$color-skill-middle: #eb5757;
$color-skill-senior: #4d5162;

$color-red-light-bg: #eb57571a;
$color-purple-light-bg: #df00ca1a;
$color-blue-light-bg: #5d83e41a;
$color-red-light-bg-2: #e82c2c1a;
$color-purple-light-bg-2: #ce3bc01a;
$color-blue-light-bg-2: #1a54e81a;

$color-group-bg: #f5f7f9;

$color-link-tg: #279eda;
$color-link-vk: #2787f5;
$color-link-fb: #1778f2;

$color-date-bg: #373b4f;

$color-avatar-coral: #ff9999;
$color-avatar-lagoon: #56ccf2;
$color-avatar-gray: #bdbdbd;
$color-avatar-purple: #de8afe;
$color-avatar-mustard: #ffb574;
$color-avatar-lavender: #a3bcff;
$color-avatar-mint: #62dba0;

//landing-
$color-landing-blue: #5d83e4;
$color-landing-blue-2: #2d9cdb;
$color-landing-white: #fff;
$color-landing-ligth-gray: #fbfbfb;
$color-landing-purple: #6358c7;
$color-landing-border: #a1a0e2;

$color-landing-blue-hover: rgba(#5d83e4, 0.9);

$color-landing-bg-gradient: linear-gradient(101.05deg, #5d83e4 -8.74%, #6d3ab8 112.46%),
	linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 18.06%);

$color-landing-main-text: #015672;
$color-landing-gray-text: #9cacbf;
$color-landing-dark-gray-text: #808080;
$color-landing-black-text: #2b142e;
$color-landing-purple-text: #6358c7;
