@import '../../../../assets/styles/mixins';

.right-column {
	width: 400px;
	margin-left: 32px;

	.panel + .panel {
		margin-top: 24px;
	}

	@include max-width(1024px) {
		width: 100%;
		margin-left: 0;

		.panel {
			margin-top: 20px;
		}

		.panel + .panel {
			padding-top: 24px;
		}
	}

	@include max-width(768px) {
		.panel {
			margin-top: 0;

			.main-page-title {
				padding: 20px 0;
			}

			.social-links {
				padding: 0;
			}
		}

		.panel + .panel {
			padding-top: 24px;
		}
	}
}
