@import '../../../../../assets/styles/colors.scss';

.profit-third {
	padding: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	&__image-wrapper {
		display: flex;
		justify-content: flex-end;
		width: 50%;
		padding-right: 80px;
		& > img {
			height: 100%;
			width: 100%;
		}
	}

	&__text-wrapper {
		width: 50%;
	}

	&__text-title {
		max-width: 400px;
		margin-bottom: 15px;
		color: $color-landing-main-text;
	}

	&__text {
		max-width: 475px;
		color: $color-landing-main-text;
	}

	@media (max-width: 1400px) {
		padding: 30px;
	}

	@media (max-width: 576px) {
		flex-direction: column-reverse;
		&__image-wrapper {
			width: 100%;
			padding: 0;
		}

		&__text-wrapper {
			width: 100%;
		}
	}
}
