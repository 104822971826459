@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/mixins';

.programm-tab-content-item {
	&__time {
		margin-bottom: 4px;
		color: $color-blue-focus;
		font-weight: 600;
		font-size: 16px;
		line-height: 120%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}

	&__title {
		margin-bottom: 4px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
	}

	&__desc {
		margin-bottom: 12px;
		pre {
			font-size: 14px;
			line-height: 20px;
		}
	}

	&__speaker {
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.04em;
	}

	@include max-width(768px) {
		margin: 0 8px;
		&:not(:last-child) {
			border-bottom: 2px solid $color-panel-border;
		}
		&__title {
			font-size: 16px;
		}
		&__desc {
			margin-bottom: 8px;
			pre {
				font-size: 12px;
			}
		}
		&__speaker {
			font-size: 12px;
		}
	}
}
