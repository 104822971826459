@import '../../../../../assets/styles/colors';

.event-gallery {
	margin-top: 50px;

	&__title {
		margin-bottom: 20px;
		padding: 0 8px 12px 8px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
		border-bottom: 2px solid $color-border-light;
	}

	&__container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 16px;
		margin-bottom: 16px;
	}

	&__item {
		position: relative;
		width: 100%;
		height: 148px;
		background-position: center;
		background-size: cover;
		cursor: pointer;

		&--overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__full-button {
		& > span {
			color: $color-blue-focus;
			line-height: 24px;
			text-transform: lowercase;
			cursor: pointer;
		}
	}
}
